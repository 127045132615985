import { useEffect } from 'react'
import { useState } from 'react'

import Auction from 'classes/Auction'
import ProductSaleList from 'classes/ProductSaleList'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import ProductSaleSimpleList from 'components/ProductSaleList/ProductSaleSimpleList'
import { SortColumn } from 'enums/SortColumn'
import clone from 'functions/clone'
import useQuery from 'hooks/useQuery'
import AuctionRendering from 'pages/AuctionDetail/components/AuctionRendering'

export default function AuctionDetail() {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [auction, setAuction] = useState<Auction>(new Auction())
	const [productSaleList, setProductSaleList] = useState<ProductSaleList>(new ProductSaleList())

	const query = useQuery()

	useEffect(() => {
		initialize()
	}, [query])

	async function initialize() {
		const id: string | null = query.get('id')
		if (id) {
			setIsLoading(true)
			try {
				await initializeAuction(Number(id))
				await initializeProductSaleList(Number(id))
			} finally {
				setIsLoading(false)
			}
		} else {
			setAuction(new Auction())
		}
	}

	async function initializeAuction(id: number) {
		await auction.apiGet(id)
		setAuction(clone(auction))
	}

	async function initializeProductSaleList(id: number) {
		await productSaleList.readFromApi({
			searchType: null,
			auction: Number(id),
			user: null,
			searchTerm: null,
			availability: 'all',
			sortColumn: SortColumn.ID,
			sortOrder: 'ASC',
			pageCount: 500,
			page: 0,
		})
		setProductSaleList(clone(productSaleList))
	}

	function getTitle(): string {
		const id: string | null = query.get('id')
		if (id) {
			return 'Auktion bearbeiten'
		}
		return 'Neue Auktion'
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				<div className="row">
					<div className="col-6">
						<h3>{getTitle()}</h3>
						<hr />
						<AuctionRendering
							auction={auction}
							setAuction={setAuction}
							setIsLoading={setIsLoading}
							productSaleList={productSaleList}
						/>
					</div>
					<div className="col-6">
						<h3 style={{ marginBottom: '15px' }}>Highlights</h3>
						<ProductSaleSimpleList items={auction.getHighlights()} />
					</div>
				</div>
			</div>
		</main>
	)
}
