import { useEffect } from 'react'
import { useState } from 'react'

import AuctionList from 'classes/AuctionList'
import CategoryList from 'classes/CategoryList'
import ProductSale from 'classes/ProductSale'
import ProductSaleList from 'classes/ProductSaleList'
import UserList from 'classes/UserList'
import ProductSaleSimpleList from 'components/ProductSaleList/ProductSaleSimpleList'
import clone from 'functions/clone'
import ProductSaleListComponent from 'pages/ProductSaleDetail/components/IdMultiple/ProductSaleListComponent'

interface Props {
	ids: string
	setIsLoading: (input: boolean) => void
	categoryList: CategoryList
	auctionList: AuctionList
	userList: UserList
}

export default function IdMultiple(props: Props) {
	const [productSaleList, setProductsSaleList] = useState<ProductSaleList>(new ProductSaleList())

	useEffect(() => {
		initialize()
	}, [props.ids])

	async function initialize() {
		const idArray: string[] = props.ids.split(';')
		productSaleList.list = []
		props.setIsLoading(true)
		try {
			for (const id of idArray) {
				const productSale = new ProductSale()
				await productSale.readFromApi(id)
				productSaleList.list.push(productSale)
				props.setIsLoading(true)
			}
			setProductsSaleList(clone(productSaleList))
		} finally {
			props.setIsLoading(false)
		}
	}

	return (
		<div className="row">
			<div
				className="col-6"
				style={{
					backgroundColor: 'white',
					paddingBottom: '12px',
					paddingTop: '15px',
				}}
			>
				<ProductSaleListComponent
					productSaleList={productSaleList}
					setProductSaleList={setProductsSaleList}
					auctionList={props.auctionList}
					categoryList={props.categoryList}
					userList={props.userList}
					setIsLoading={props.setIsLoading}
				/>
			</div>
			<div className="col-6">
				<div>
					<ProductSaleSimpleList items={productSaleList.list} />
				</div>
			</div>
		</div>
	)
}
