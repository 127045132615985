import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import User from 'classes/User'
import clone from 'functions/clone'
import { isUserAdmin } from 'functions/token'

export default function Navigation() {
	const [user, setUser] = useState<User>(new User())

	const navigate = useNavigate()

	useEffect(() => {
		initialize()
	}, [])

	function handleClick(input: string) {
		navigate(input)
	}

	function initialize() {
		user.mapJwtToThis()
		setUser(clone(user))
	}

	function logout(): void {
		localStorage.clear()
		navigate('/login')
	}

	function getUserDisplay(): string {
		if (user.isAdmin) {
			return user.firstname + ' ' + user.lastname + ' (Admin)'
		}
		return user.firstname + ' ' + user.lastname
	}

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-dark" style={{ zIndex: 30 }}>
			<div className="container-fluid">
				<a className="navbar-brand" href="#">
					R&J CMS
				</a>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav me-auto mb-2 mb-lg-0">
						<li
							className="nav-item"
							onClick={() => handleClick('/product/search')}
							style={{ cursor: 'pointer' }}
						>
							<div className="nav-link active" aria-current="page">
								Produkte
							</div>
						</li>
						{isUserAdmin() && (
							<li className="nav-item dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="#"
									id="navbarDropdown"
									role="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									Auktionen
								</a>
								<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
									<li
										onClick={() => handleClick('/auction/search')}
										style={{ cursor: 'pointer' }}
									>
										<div className="dropdown-item">Suchen</div>
									</li>
									<li
										onClick={() => handleClick('/auction/new')}
										style={{ cursor: 'pointer' }}
									>
										<div className="dropdown-item">Erstellen</div>
									</li>
								</ul>
							</li>
						)}
						{isUserAdmin() && (
							<li className="nav-item dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="#"
									id="navbarDropdown"
									role="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									Einlieferer
								</a>
								<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
									<li
										onClick={() => handleClick('/user/search')}
										style={{ cursor: 'pointer' }}
									>
										<div className="dropdown-item">Suchen</div>
									</li>
									<li
										onClick={() => handleClick('/user/new')}
										style={{ cursor: 'pointer' }}
									>
										<div className="dropdown-item">Erstellen</div>
									</li>
								</ul>
							</li>
						)}
					</ul>
					<div className="d-flex" style={{ gap: '20px' }}>
						<li className="d-flex nav-item" style={{ color: 'grey' }}>
							{getUserDisplay()}
						</li>
						<li
							className="d-flex nav-item"
							style={{ cursor: 'pointer', color: 'white' }}
							onClick={logout}
						>
							Abmelden
						</li>
					</div>
				</div>
			</div>
		</nav>
	)
}
