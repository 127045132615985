import { Availability } from 'enums/Availability'
import clone from 'functions/clone'
import Search from 'pages/ProductSaleSearch/classes/Search'

interface Props {
	search: Search
	setSearch: (input: Search) => void
	handleSearch: () => Promise<void>
}

export default function AvailabilitySliderComponent(props: Props) {
	async function handleChangeSwitch(input: Availability): Promise<void> {
		props.search.availability.change(input)
		props.setSearch(clone(props.search))
		await props.handleSearch()
	}

	function getChecked(input: Availability): boolean {
		if (props.search.availability.get() === Availability.ALL) return true
		if (props.search.availability.get() === input) return true
		return false
	}

	return (
		<>
			<div className="form-check">
				<input
					onChange={() => handleChangeSwitch(Availability.AVAILABLE)}
					checked={getChecked(Availability.AVAILABLE)}
					className="form-check-input"
					type="checkbox"
					id="checkbox-available"
				/>
				<label
					className="form-check-label"
					htmlFor="checkbox-available"
					style={{
						marginTop: '-2px',
						opacity: getChecked(Availability.AVAILABLE) ? 1 : 0.5,
					}}
				>
					<span className="badge bg-success">Verfügbar</span>
				</label>
			</div>
			<div className="form-check">
				<input
					onChange={() => handleChangeSwitch(Availability.SOLD)}
					checked={getChecked(Availability.SOLD)}
					className="form-check-input"
					type="checkbox"
					id="checkbox-sold"
				/>
				<label
					className="form-check-label"
					htmlFor="checkbox-sold"
					style={{
						marginTop: '-2px',
						opacity: getChecked(Availability.SOLD) ? 1 : 0.5,
					}}
				>
					<span className="badge bg-danger">Verkauft</span>
				</label>
			</div>
		</>
	)
}
