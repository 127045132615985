import AuctionList from 'classes/AuctionList'
import UserList from 'classes/UserList'
import SearchTermFieldComponent from 'components/SearchTermField'
import { isUserAdmin } from 'functions/token'
import Search from 'pages/ProductSaleSearch/classes/Search'
import SearchTypeSelectComponent from 'pages/ProductSaleSearch/components/SearchTypeSelect'

import AuctionSelectComponent from './AuctionSelect'
import AvailabilitySliderComponent from './AvailabilitySlider'
import UserSelectComponent from './UserSelect'

interface Props {
	search: Search
	setSearch: (input: Search) => void
	handleSearch: () => Promise<void>
	auctionList: AuctionList
	userList: UserList
}

export default function SearchComponent(props: Props) {
	return (
		<div className="row">
			<div className="col-6">
				<SearchTermFieldComponent
					search={props.search}
					setSearch={props.setSearch}
					handleSearch={props.handleSearch}
				/>
				<SearchTypeSelectComponent
					search={props.search}
					setSearch={props.setSearch}
					handleSearch={props.handleSearch}
				/>
			</div>
			<div className="col-6">
				<AuctionSelectComponent
					search={props.search}
					setSearch={props.setSearch}
					handleSearch={props.handleSearch}
					auctionList={props.auctionList}
				/>
				{isUserAdmin() && (
					<>
						<div className="mt-3"></div>
						<UserSelectComponent
							search={props.search}
							setSearch={props.setSearch}
							handleSearch={props.handleSearch}
							userList={props.userList}
						/>
					</>
				)}
				<div className="mt-3"></div>
				<AvailabilitySliderComponent
					search={props.search}
					setSearch={props.setSearch}
					handleSearch={props.handleSearch}
				/>
			</div>
		</div>
	)
}
