import * as api from 'api/UserList/UserList'

import UserListApi from '../interfaces/UserListApi'
import User from './User'

export default class UserList {
	public all: User[] = []
	public searched: User[] = []
	public count: number = 0

	public constructor(input?: UserListApi) {
		if (input) {
			this.mapApiToThis(input)
		}
	}
	public async initialize() {
		await this.readFromApi()
	}

	public async readFromApi() {
		const response: UserListApi | null = await api.call()
		if (response) {
			this.mapApiToThis(response)
		}
	}

	public getUserById(id: number | null): User | undefined {
		return this.all.find((e: User) => {
			return e.id === id
		})
	}

	private mapApiToThis(input: UserListApi): void {
		this.all = []
		this.count = input.count
		for (const item of input.all) {
			const productSell = new User(item)
			this.all.push(productSell)
		}
	}

	public toggleSelectById(id: number): void {
		const user: User | undefined = this.getById(id)
		if (user) {
			user.selected = !user.selected
		}
	}

	public getById(id: number | null): User | undefined {
		if (id === null) return undefined
		return this.all.find((e: User) => {
			return e.id === id
		})
	}

	public selectAll(): void {
		for (const i of this.all) {
			i.selected = true
		}
	}

	public unselectAll(): void {
		for (const i of this.all) {
			i.selected = false
		}
	}

	public getCountOfSelected(): number {
		const check: User[] = this.all.filter((e: User) => {
			if (e.selected === true) {
				return e
			}
		})
		return check.length
	}

	public async deleteSelectedFromApi(): Promise<void> {
		//const user: User | undefined = this.getFirstSelected()
		//if (user) {
		//await user.apiDelete()
		//this.removeFromAll(user)
		//}
	}

	public getFirstSelected(): User | undefined {
		return this.all.find((e: User) => {
			return e.selected
		})
	}

	public setSearched(input: string): void {
		const users: User[] = this.all.filter((e: User) => {
			if (e.getName().toLowerCase().includes(input.toLowerCase())) {
				return e
			}
		})
		this.searched = users
	}
}
