import { useState } from 'react'

import AuctionList from 'classes/AuctionList'
import CategoryList from 'classes/CategoryList'
import ProductSaleList from 'classes/ProductSaleList'
import UserList from 'classes/UserList'
import ActionButtons from 'pages/ProductSaleDetail/components/IdMultiple/ActionButtons'
import InputNumber from 'pages/ProductSaleDetail/components/InputNumber'
import InputString from 'pages/ProductSaleDetail/components/InputString'
import SelectComponent from 'pages/ProductSaleDetail/components/Select'
import SoldCheckbox from 'pages/ProductSaleDetail/components/SoldCheckbox'
import getAuctionOptionList from 'pages/ProductSaleDetail/functions/getAuctionOptionList'
import getTypeOptionList from 'pages/ProductSaleDetail/functions/getTypeOptionList'

interface Props {
	productSaleList: ProductSaleList
	setProductSaleList: (input: ProductSaleList) => void
	categoryList: CategoryList
	auctionList: AuctionList
	userList: UserList
	setIsLoading: (input: boolean) => void
}

type Values = {
	type: number | null
	auctionId: number | null
	zNr: string | null
	zNrNumber: string | null
	nameDe: string | null
	year: string | null
	price: number | null
	priceProposal: number | null
	sold: boolean | null
}

export default function ProductSaleListComponent(props: Props) {
	const [values, setValues] = useState<Values>({
		type: null,
		auctionId: null,
		zNr: null,
		zNrNumber: null,
		nameDe: null,
		year: null,
		price: null,
		priceProposal: null,
		sold: null,
	})

	function transferValuesToProductSaleList(): void {
		if (values.type) props.productSaleList.setAllTypes(values.type)
		if (values.auctionId) props.productSaleList.setAllAuctionIds(values.auctionId)
		if (values.zNr) props.productSaleList.setAllZNrs(values.zNr)
		if (values.zNrNumber) props.productSaleList.setAllZNrNumbers(values.zNrNumber)
		if (values.nameDe) props.productSaleList.setAllNames(values.nameDe)
		if (values.year) props.productSaleList.setAllNames(values.year)
		if (values.price) props.productSaleList.setAllPrices(values.price)
		if (values.priceProposal) props.productSaleList.setAllPriceProposals(values.priceProposal)
		if (values.sold !== null) props.productSaleList.setAllSoldBooleans(values.sold)
	}

	function changeType(input: string): void {
		const valuesCopy: Values = { ...values }
		valuesCopy.type = input ? Number(input) : null
		setValues(valuesCopy)
	}

	function changeZNr(input: string) {
		const valuesCopy: Values = { ...values }
		valuesCopy.zNr = input ? input : null
		setValues(valuesCopy)
	}

	function changeAuction(input: string): void {
		const valuesCopy: Values = { ...values }
		valuesCopy.auctionId = input ? Number(input) : null
		setValues(valuesCopy)
	}

	function changeZNrNumber(input: string) {
		const valuesCopy: Values = { ...values }
		valuesCopy.zNrNumber = input ? input : null
		setValues(valuesCopy)
	}

	function changeNameDe(input: string) {
		const valuesCopy: Values = { ...values }
		valuesCopy.nameDe = input ? input : null
		setValues(valuesCopy)
	}

	function changeYear(input: string) {
		const valuesCopy: Values = { ...values }
		valuesCopy.year = input ? input : null
		setValues(valuesCopy)
	}

	function changePrice(input: number) {
		const valuesCopy: Values = { ...values }
		valuesCopy.price = input ? input : null
		setValues(valuesCopy)
	}

	function changePriceProposal(input: number) {
		const valuesCopy: Values = { ...values }
		valuesCopy.priceProposal = input ? input : null
		setValues(valuesCopy)
	}

	function changeSold(input: boolean | null) {
		const valuesCopy: Values = { ...values }
		valuesCopy.sold = input
		setValues(valuesCopy)
	}

	return (
		<>
			<div className="d-flex justify-content-between">
				<h3>Mehrere Produkte bearbeiten</h3>
			</div>
			<hr />
			<div className="d-flex justify-content-between">
				<ActionButtons
					productSaleList={props.productSaleList}
					setProductSaleList={props.setProductSaleList}
					setIsLoading={props.setIsLoading}
					transferValuesToProductSaleList={transferValuesToProductSaleList}
				/>
				<div style={{ opacity: values.sold === null ? 0.4 : 1 }}>
					<SoldCheckbox handleToggle={changeSold} sold={values.sold} />
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-12">
					<SelectComponent
						optionList={getTypeOptionList()}
						label="Typ"
						changeValue={changeType}
						defaultValue={
							values.type
								? String(values.type)
								: props.productSaleList.getAllTypesString()
						}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-12">
					<SelectComponent
						isDisabled={values.type !== 2}
						optionList={getAuctionOptionList(props.auctionList)}
						label="Auktion"
						changeValue={changeAuction}
						defaultValue={
							values.auctionId
								? String(values.auctionId)
								: props.productSaleList.getAllAuctionsString()
						}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-6">
					<InputString
						label="Zumstein-Nr"
						value={values.zNr ?? ''}
						placeholder={props.productSaleList.getAllZnrString()}
						changeValue={changeZNr}
					/>
				</div>
				<div className="col-6">
					<InputString
						label="Zumstein-Nr Nummer"
						value={values.zNrNumber ?? ''}
						placeholder={props.productSaleList.getAllZnrNumberString()}
						changeValue={changeZNrNumber}
						color={'#ffdede'}
					/>
				</div>
			</div>
			<div className="row mt-2">
				<InputString
					label="Titel (deutsch)"
					value={values.nameDe ?? ''}
					placeholder={props.productSaleList.getNumberOfValuesString()}
					changeValue={changeNameDe}
				/>
			</div>
			<div className="row mt-2">
				<InputString
					label="Jahr"
					value={values.year ?? ''}
					placeholder={props.productSaleList.getAllYearString()}
					changeValue={changeYear}
				/>
			</div>
			<div className="row mt-2">
				<div className="col-6">
					<InputNumber
						label="Katalogwert (in CHF)"
						defaultValue={props.productSaleList.getNumberOfValuesString()}
						changeValue={changePriceProposal}
					/>
				</div>
				<div className="col-6">
					<InputNumber
						label="Verkaufspreis (in CHF)"
						defaultValue={props.productSaleList.getNumberOfValuesString()}
						changeValue={changePrice}
					/>
				</div>
			</div>
		</>
	)
}
