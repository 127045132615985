import { useEffect } from 'react'
import { useState } from 'react'

import AuctionList from 'classes/AuctionList'
import CategoryList from 'classes/CategoryList'
import UserList from 'classes/UserList'
import LoadingOverarching from 'components/Loading/Overarching/LoadingOverarching'
import Navigation from 'components/Navigation'
import clone from 'functions/clone'
import useQuery from 'hooks/useQuery'
import IdMultiple from 'pages/ProductSaleDetail/components/IdMultiple/IdMultiple'
import IdSingle from 'pages/ProductSaleDetail/components/IdSingle/IdSingle'

export default function ProductSaleDetailPage() {
	const [userList, setUserList] = useState<UserList>(new UserList())
	const [categoryList, setCategoryList] = useState<CategoryList>(new CategoryList())
	const [auctionList, setAuctionList] = useState<AuctionList>(new AuctionList())
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const query = useQuery()

	useEffect(() => {
		initialize()
	}, [query])

	async function initialize() {
		try {
			await categoryList.readFromApi()
			setCategoryList(clone(categoryList))
			await userList.readFromApi()
			setUserList(clone(userList))
			await auctionList.readFromApi()
			setAuctionList(clone(auctionList))
		} catch (e: unknown) {
			console.error(e)
		}
	}

	function render() {
		const id: string | null = query.get('id')
		const ids: string | null = query.get('ids')

		if (id) {
			return (
				<IdSingle
					id={id}
					setIsLoading={setIsLoading}
					categoryList={categoryList}
					auctionList={auctionList}
					userList={userList}
				/>
			)
		}
		if (ids) {
			return (
				<IdMultiple
					ids={ids}
					setIsLoading={setIsLoading}
					categoryList={categoryList}
					auctionList={auctionList}
					userList={userList}
				/>
			)
		}
		return <h1>404</h1>
	}

	return (
		<main>
			<Navigation />
			<div className="container mt-4">
				<LoadingOverarching isLoading={isLoading} />
				{render()}
			</div>
		</main>
	)
}
