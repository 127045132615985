import * as apiLogin from 'api/Login/LoginPost'
import * as apiGetUser from 'api/User/GetUserById'
import * as apiPost from 'api/User/PostUser'
import * as apiPut from 'api/User/PutUserById'
import { setToken } from 'functions/token'
import { getToken } from 'functions/token'
import { parseJwt } from 'functions/token'
import UserApi from 'interfaces/UserApi'

export type userPropsString = 'username' | 'firstname' | 'lastname' | 'email'
export type userPropsNumber = 'id' | 'number'
export type userPropsBoolean = 'isAdmin'

export default class User {
	public id: number | undefined = undefined
	public username: string = ''
	public password: string = ''
	public number: number = 0
	public firstname: string = ''
	public lastname: string = ''
	public email: string = ''
	public isAdmin: boolean = false
	selected: boolean = false

	public constructor(input?: UserApi) {
		if (input) {
			this.mapApiToThis(input)
		}
	}

	public getName(): string {
		return this.firstname + ' ' + this.lastname
	}

	public async login(): Promise<boolean> {
		const response: apiLogin.ResponseData | null = await apiLogin.apiCall(
			this.username,
			this.password
		)
		if (response) {
			setToken(response.token)
			return true
		}
		return false
	}

	public async save(): Promise<{
		status: number
		id: number | null
	}> {
		if (this.id) {
			const response: apiPut.Response = await this.apiPut()
			return {
				status: response.status,
				id: null,
			}
		}
		const response: apiPost.Response = await this.apiPost()
		return {
			status: response.status,
			id: response.data?.data ?? null,
		}
	}

	private mapToApi(): UserApi {
		return {
			id: this.id,
			username: this.username,
			number: this.number,
			firstname: this.firstname,
			lastname: this.lastname,
			isAdmin: this.isAdmin,
			email: this.email,
			password: this.password,
		}
	}

	public async apiPut(): Promise<apiPut.Response> {
		return await apiPut.call(this.mapToApi())
	}

	public async apiPost(): Promise<apiPost.Response> {
		return await apiPost.call(this.mapToApi())
	}

	public async apiGetById(id: number): Promise<void> {
		const user: UserApi | null = await apiGetUser.call(id)
		if (user) {
			this.mapApiToThis(user)
		}
	}

	public mapJwtToThis() {
		const token: string | null = getToken()
		if (token) {
			const userApi: UserApi = parseJwt(token)
			this.mapApiToThis(userApi)
		}
	}

	private mapApiToThis(input: UserApi) {
		this.id = input.id
		this.username = input.username
		this.number = input.number
		this.firstname = input.firstname
		this.lastname = input.lastname
		this.isAdmin = input.isAdmin
		this.email = input.email
	}
}
