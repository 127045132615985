import axios from 'axios'

import { ProductSaleApi } from 'interfaces/ProductSaleApi'

export async function call(input: Request): Promise<ResponseData | null> {
	try {
		const response: Response = await axios.post('/product-sale-list/all', input)
		return response.data
	} catch (e: unknown) {
		console.error(e)
	}
	return null
}

export type Request = {
	searchType: SearchType
	auction: number | null
	user: number | null
	searchTerm: string | null
	availability: Availability
	sortColumn: SortColumn
	sortOrder: SortOrder
	pageCount: number
	page: number
}

export type Availability = 'all' | 'available' | 'sold'

export type SortOrder = 'ASC' | 'DESC'

export type SortColumn = 'id' | 'type' | 'znr' | 'year' | 'name' | 'category' | 'sortOrder'

export type SearchType = 'auction' | 'shop' | null

export type Response = {
	data: ResponseData
}

export type ResponseData = {
	data: ProductSaleApi[]
	count: number
}
